import { Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import { colors, StyledPaper } from "merchant-core";

type BannerProps = {
  title: string;
  subtitle?: string;
};

export const Banner: FunctionComponent<BannerProps> = ({ title, subtitle }) => {
  return (
    <StyledPaper sx={{
      p: 2,
      backgroundColor: colors.PURPLE_DARKEST,
      color: colors.PURE_WHITE
    }}>
      <Typography variant="h1" sx={{ marginBottom: "12px" }}>
        {title}
      </Typography>
      {!!subtitle && <Typography>{subtitle}</Typography>}
    </StyledPaper>
  );
};
