import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { merchantOnboardingHubApiUrl } from "../../constants/apiUrls";
import { OnboardingMerchantId } from "merchant-core";
import { useMerchantUser } from "../useMerchantUser";
import { onboardingMerchantQueryKey } from "./useOnboardingMerchant";
import { client } from "../../api/client";
import { useTracking } from "../useTracking";

const COMPLETE_ONBOARDING_URL = (merchantId: OnboardingMerchantId): string => {
  return `${merchantOnboardingHubApiUrl}/${merchantId}/onboarding-hub/complete`;
};

const completeOnboarding = async (
  merchantId: OnboardingMerchantId,
): Promise<unknown> => {
  const response = await client.post(COMPLETE_ONBOARDING_URL(merchantId));
  return response.data;
};

export const useCompleteOnboarding = (): UseMutationResult<
  unknown,
  Error,
  void
> => {
  const { merchantId } = useMerchantUser();
  const queryClient = useQueryClient();
  const { publishEvent } = useTracking();

  return useMutation<unknown, Error, void>({
    mutationFn: async () => completeOnboarding(merchantId),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    onSuccess: async () => {
      await publishEvent("ONBOARDING_COMPLETED");
      // clear out root merchant info so that it re-fetches
      queryClient.invalidateQueries({
        queryKey: onboardingMerchantQueryKey(merchantId),
      });
    },
  });
};
