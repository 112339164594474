import React, { FunctionComponent, useContext } from "react";
import { CircularProgress, Container, Grid, Stack } from "@mui/material";
import { Banner } from "../components/Banner";
import { Checklist } from "../components/Checklist";
import { ChecklistItem } from "../components/ChecklistItem";
import { useOnboardingMerchant } from "../hooks/api/useOnboardingMerchant";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { GetStaticProps } from "next";
import { CompleteOnboarding } from "../components/CompleteOnboarding";
import { useRouteGuards } from "../hooks/useRouteGuards";
import { isMerchantDeclined } from "../utils/decline";
import { NextRouter, useRouter } from "next/router";
import { Footer } from "../components/Footer";
import CalendarIcon from "../components/Icons/CalendarIcon";
import { SettingsContext } from "../contexts/settings/SettingsContext";

const getReturnUrl = (router: NextRouter): string | null => {
  if (!router?.query) {
    return null;
  }

  const returnUrlParam = Array.isArray(router.query.returnUrl)
    ? router.query.returnUrl[0]
    : router.query.returnUrl;

  return returnUrlParam || null;
};

const getCurrentPath = (): string | null => {
  if (!window?.location) {
    return null;
  }

  return window.location.pathname + window.location.search;
};

const shouldRedirect = (router: NextRouter): boolean => {
  const returnUrl = getReturnUrl(router);
  if (!returnUrl) {
    return false;
  }

  const currentPath = getCurrentPath();
  if (!currentPath) {
    return false;
  }

  return returnUrl !== currentPath;
};

const Index: FunctionComponent = () => {
  const { t } = useTranslation("home");
  const { isDevelopmentMode } = useContext(SettingsContext);
  const { data: onboardingMerchant, isLoading } = useOnboardingMerchant();
  const router = useRouter();
  const routeGuards = useRouteGuards();

  const redirectRequired = shouldRedirect(router);
  const redirectUrl = getReturnUrl(router);
  if (redirectRequired && !!redirectUrl) {
    router.push(redirectUrl);
    return <></>;
  }

  if (isLoading) {
    return (
      <Stack alignItems="center" mt="40px">
        <CircularProgress size={50} color="secondary" />
      </Stack>
    );
  }

  if (!onboardingMerchant) {
    return <>No onboarding merchant!</>;
  }

  if (isMerchantDeclined(onboardingMerchant)) {
    router.push("/sorry");
    return <></>;
  }

  const businessName = onboardingMerchant.businessInfo?.businessName;
  const platform =
    onboardingMerchant.businessInfo?.platform ?? onboardingMerchant.platform;

  const isInReview = onboardingMerchant.isInReview;

  const isMsaReady = !!onboardingMerchant.dueDiligenceSucceeded;

  const isOnboardingComplete = !!onboardingMerchant.integrationVerified;

  const banner = isInReview ? (
    <Banner
      title={t("in-review.title")}
      subtitle={
        onboardingMerchant.isWhiteGloveExperience
          ? t("in-review.white-glove")
          : t("in-review.non-white-glove")
      }
    />
  ) : (
    <Banner title={t("banner.title")} subtitle={t("banner.subtitle")} />
  );

  return (
    <Container maxWidth="lg" id="containerDashboard">
      <Grid container>
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          {banner}

          <Checklist>
            <ChecklistItem
              estimatedTime={t("checklist.accountDetails.estimatedTime")}
              primaryText={t("checklist.accountDetails.stepName")}
              checked={onboardingMerchant.businessInfoCompleted}
              href="/business-info"
              disabled={!routeGuards.businessInfo(onboardingMerchant)}
            />

            <ChecklistItem
              estimatedTime={t("checklist.businessInfo.estimatedTime")}
              primaryText={t("checklist.businessInfo.stepName", {
                businessName: businessName || "your business",
              })}
              disabled={!routeGuards.complianceInfo(onboardingMerchant)}
              checked={onboardingMerchant.complianceInfoCompleted}
              href="/tell-us-more"
            />

            <ChecklistItem
              estimatedTime={t("checklist.linkBankAccount.estimatedTime")}
              primaryText={t("checklist.linkBankAccount.stepName")}
              disabled={!routeGuards.stripeConnect(onboardingMerchant)}
              hidden={
                onboardingMerchant.isWhiteGloveExperience ||
                onboardingMerchant.stripeIntegrationBypassed
              }
              checked={onboardingMerchant.stripeAccountCompleted}
              href="/bank-account"
            />

            <ChecklistItem
              estimatedTime={t("checklist.inReview.estimatedTime")}
              primaryText={t("checklist.inReview.stepName")}
              icon={<CalendarIcon />}
              disabled={!onboardingMerchant.isInReview}
              checked={onboardingMerchant.dueDiligenceSucceeded}
              href="/review"
              tooltip={t("checklist.inReview.tooltip")}
            />

            <ChecklistItem
              primaryText={t("checklist.msaSigned.stepName")}
              estimatedTime={t("checklist.msaSigned.estimatedTime")}
              checked={onboardingMerchant.msaSigned}
              disabled={onboardingMerchant.msaSigned || !isMsaReady}
              tooltip={
                isMsaReady ? t("checklist.msaSigned.tooltip") : undefined
              }
              href="/merchant-service-agreement"
            />

            {onboardingMerchant.isWhiteGloveExperience ? (
              <ChecklistItem
                primaryText={t("checklist.integrationsTeam.stepName")}
                estimatedTime={t("checklist.integrationsTeam.estimatedTime")}
                disabled={!isDevelopmentMode && !onboardingMerchant.msaSigned}
                href="/integrations"
              />
            ) : (
              <>
                <ChecklistItem
                  estimatedTime={t("checklist.addWidget.estimatedTime")}
                  hidden={
                    !platform ||
                    platform == "custom" ||
                    onboardingMerchant.isWhiteGloveExperience
                  }
                  primaryText={t("checklist.addWidget.stepName")}
                  disabled={!routeGuards.widgetIntegration(onboardingMerchant)}
                  checked={onboardingMerchant.widgetVerified}
                  href="/widget"
                />

                <ChecklistItem
                  estimatedTime={t("checklist.addCheckout.estimatedTime")}
                  primaryText={t("checklist.addCheckout.stepName")}
                  checked={onboardingMerchant.integrationVerified}
                  disabled={!routeGuards.pluginIntegration(onboardingMerchant)}
                  href="/checkout"
                />
              </>
            )}

            {isOnboardingComplete ? <CompleteOnboarding /> : <></>}
          </Checklist>
        </Grid>
      </Grid>

      <Footer />
    </Container>
  );
};

export default Index;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(locale && (await serverSideTranslations(locale, ["common", "home"]))),
    },
  };
};
