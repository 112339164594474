import {
  Alert,
  CircularProgress,
  Collapse,
  Stack,
  Typography,
} from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useCompleteOnboarding } from "../hooks/api/useCompleteOnboarding";
import Image from "next/image";
import highFiveIcon from "../public/images/Hi_Five.svg";

const INITIAL_WAIT_DURATION_SECONDS = 15; // 15

const CompletionError: FunctionComponent = () => {
  const [open, setOpen] = useState(true);

  return (
    <Collapse in={open} unmountOnExit>
      <Alert onClose={() => setOpen(false)} severity="error">
        Something went wrong&hellip; You haven&apos;t completed a required step
        for sign-up.
      </Alert>
    </Collapse>
  );
};

const CompletionSuccess: FunctionComponent = () => {
  const [countdown, setCountdown] = useState(INITIAL_WAIT_DURATION_SECONDS);
  const countdownCompleted = countdown <= 0;

  useEffect(() => {
    const secondInterval = window.setInterval(() => {
      setCountdown((c) => Math.max(c - 1, 0));
    }, 1000);

    return () => window.clearInterval(secondInterval);
  }, []);

  useEffect(() => {
    if (countdownCompleted) {
      window.location.href =
        process.env.NEXT_PUBLIC_MERCHANT_PORTAL_URL ||
        "https://merchant.us.zip.co";
    }
  }, [countdownCompleted]);

  const countdownText = countdown === 0 ? "now" : `in ${countdown} seconds`;

  return (
    <Alert severity="success">
      You&apos;re ready to go! You&apos;ll be redirected to the merchant portal{" "}
      {countdownText}.
    </Alert>
  );
};

export const CompleteOnboarding: FunctionComponent = () => {
  const { isSuccess, isError, isLoading, mutateAsync, reset } =
    useCompleteOnboarding();

  useEffect(() => {
    const triggerMutation = async (): Promise<void> => {
      reset();
      mutateAsync();
    };
    triggerMutation();
  }, [reset, mutateAsync]);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      minHeight="40vh"
      spacing={2}
    >
      <Stack>
        <Image src={highFiveIcon} alt="High five!" />
      </Stack>
      <Typography variant="h2">You&apos;re ready to go with Zip!</Typography>

      {isLoading && <CircularProgress size={24} color="secondary" />}
      {isError && <CompletionError />}
      {isSuccess && <CompletionSuccess />}
    </Stack>
  );
};
