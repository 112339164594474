import { SvgIcon } from "@mui/material";
import React from "react";

const CalendarIcon = (): JSX.Element => (
  <SvgIcon>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(0.75 0 0 0.75 0 0)">
        <path
          d="M26 5H6C5.44772 5 5 5.44772 5 6V26C5 26.5523 5.44772 27 6 27H26C26.5523 27 27 26.5523 27 26V6C27 5.44772 26.5523 5 26 5Z"
          stroke="#1A0826"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 3V7"
          stroke="#1A0826"
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M10 3V7"
          stroke="#1A0826"
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M5 11H27"
          stroke="#1A0826"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  </SvgIcon>
);

export default CalendarIcon;
