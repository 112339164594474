import React, { FunctionComponent, PropsWithChildren } from "react";
import styled from "styled-components";
import { List } from "@mui/material";

import { colors, StyledPaper } from "merchant-core";

const StyledList = styled(List)`
  .MuiListItem-root {
    border-bottom: 1px solid ${colors.BORDER_GREY};

    &:last-child {
      border-bottom: none;
    }
  }
`;

export const Checklist: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <StyledPaper sx={{ borderRadius: "16px", p: 0 }}>
      <StyledList sx={{ paddingTop: 0, paddingBottom: 0 }}>
        {children}
      </StyledList>
    </StyledPaper>
  );
};
